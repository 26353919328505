import React from 'react';
export const Nav31DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page lsu2b8kq6e-editor_css' },
  logo: {
    className: 'header3-logo lsu2agu0aug-editor_css',
    children:
      'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E6%9E%9C%E6%97%A0%E9%99%90%E5%9B%BE%E6%96%87logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item ant-menu-submenu lsu2jcn1ewm-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>企业宗旨</p>
                </span>
              ),
              name: 'text',
              className: 'lsu2enpivpa-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header3-item lsu2gh3t71-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>产品交互</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
              className: 'lsu2f5uh23b-editor_css',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item2',
        className: 'header3-item lsu2o0r4qzp-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>精挑细选</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item lsu2rg3zode-editor_css ant-menu-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>匠心服务</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner01DataSource = {
  wrapper: { className: 'banner0 lr0ic3ap9nf-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper lr5w6qx0up-editor_css' },
  title: {
    className: 'banner0-title lr619aghqvl-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                送亲人&nbsp; 送爱人&nbsp; 送友人&nbsp; 送师长&nbsp; 送同学&nbsp;
                送自己
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'banner0-content lr2578pla4-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>
              <b>
                <i>用一份果礼，为情感加温！</i>
              </b>
            </p>
          </span>
        </span>
      </span>
    ),
  },
  button: {
    className: 'banner0-button lr61yoqy1au-editor_css',
    children: (
      <span>
        <p>了解“果无限”</p>
      </span>
    ),
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 lr62cg0a3d7-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>产品交互</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lr60p2onzb-editor_css',
              children:
                'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E5%9B%BE%E7%89%87/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%88%AA%E5%9B%BE01.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title lr60rybrcgo-editor_css',
              children: (
                <span>
                  <span>
                    <p>应季果品展示页面</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>二十四节气，全年树熟鲜果供应</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lr60q175lx-editor_css',
              children:
                'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E5%9B%BE%E7%89%87/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%88%AA%E5%9B%BE02.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title lr627jg5m6b-editor_css',
              children: (
                <span>
                  <p>多地址下单页面</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>批量下单，让送礼变得更简单</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lr60rdcve6-editor_css',
              children:
                'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E5%9B%BE%E7%89%87/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E6%88%AA%E5%9B%BE03.jpg',
            },
            {
              name: 'title',
              className: 'content0-block-title lr6290pzov-editor_css',
              children: (
                <span>
                  <p>红包下单页面</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>送礼新玩法，送的不尴尬</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5 lr62bsysnpo-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>精挑细选</p>
            </span>
          </span>
        ),
        className: 'title-h1 lr62951pi7l-editor_css',
      },
      {
        name: 'content',
        className: 'title-content lr625a4jqhc-editor_css',
        children: (
          <span>
            <p>全年水果，跨周期订阅</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content', href: '' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81018.jpeg',
            className: 'lr5zt3sb8et-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>立春</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content lr652l91af-editor_css',
          },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81019.jpeg',
            className: 'lr5ztxu5qt-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>春分</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81022.jpeg',
            className: 'lr5zu3oofml-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>立夏</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81023.jpeg',
            className: 'lr5zudyv1yk-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>夏至</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81024.jpeg',
            className: 'lr5zup3hok-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>立秋</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81025.jpeg',
            className: 'lr5zv7hndon-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>秋分</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81026.jpeg',
            className: 'lr5zvgq044q-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>立冬</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://guowuxian-server-prod.oss-cn-shenzhen.aliyuncs.com/%E6%9E%9C%E6%97%A0%E9%99%90%E5%AE%98%E7%BD%91new/%E9%80%89%E5%93%81/%E6%B0%B4%E6%9E%9C%E9%80%89%E5%93%81027.jpeg',
            className: 'lr5zvqjtnw-editor_css',
          },
          content: {
            children: (
              <span>
                <p>冬至</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper lr62fhjeyk-editor_css',
  },
  page: { className: 'home-page content3 lr62f6mw9b-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>匠心服务</p>
            </span>
          </span>
        ),
        className: 'title-h1 lr62ap29thk-editor_css',
      },
      {
        name: 'content',
        className: 'title-content lr625pfruck-editor_css',
        children: (
          <span>
            <p>基于起心动念的热爱与坚持</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>探访果园</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>三年时间，20+省份，300+果园，行程10万km</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>精选果品</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>精选100+精致果园，坚信“小园子，才好吃”，为食者造福</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>现采现发</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>几乎所有的水果，树熟的口感最佳，坚持现采现发</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>数据化服务</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>小程序+AI自动化接单体系，解决全链路数据跟踪响应</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>订单农业</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <p>依托于数据分析，实现以销定产，为耕者谋利</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>企业订制</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>策划、设计、包装一站式订制服务，支持一件代发、落地配</p>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: {
    className: 'footer0 lr61tpdw0u-editor_css home-page',
    playScale: 0.05,
  },
  copyright: {
    className: 'copyright lr61pvv2qj8-editor_css',
    children: (
      <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: 'black' }}>
        粤ICP备2023028607号-1
        ©2024 GuWuXian&nbsp;All Rights Reserved
      </a>
    ),
  },
};
